<template>
  <div class="flex flex-col mt-20 items-center justify-center">
    <svg
      class=""
      width="769"
      height="229"
      viewBox="0 0 169 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.2745 27.036L21.6825 28.728L19.9185 26.1C17.9025 27.084 15.8385 27.576 13.7265 27.576C9.98249 27.576 6.83848 26.28 4.29448 23.688C1.75048 21.12 0.478484 17.928 0.478484 14.112C0.478484 10.296 1.75048 7.104 4.29448 4.536C6.83848 1.944 9.98249 0.647998 13.7265 0.647998C17.4705 0.647998 20.6145 1.956 23.1585 4.572C25.7025 7.14 26.9745 10.32 26.9745 14.112C26.9745 16.128 26.5785 18.012 25.7865 19.764C25.0185 21.516 23.9145 23.04 22.4745 24.336L24.2745 27.036ZM13.7265 24.408C15.2625 24.408 16.7145 24.072 18.0825 23.4L15.0585 18.864L17.6505 17.1L20.6385 21.6C22.6065 19.704 23.5905 17.208 23.5905 14.112C23.5905 11.112 22.6425 8.652 20.7465 6.732C18.8745 4.788 16.5345 3.816 13.7265 3.816C10.9185 3.816 8.56648 4.788 6.67048 6.732C4.79848 8.676 3.86248 11.136 3.86248 14.112C3.86248 17.088 4.79848 19.548 6.67048 21.492C8.56648 23.436 10.9185 24.408 13.7265 24.408ZM68.1703 1.224H71.4823V9.36L71.3383 11.808H71.4823C71.9863 10.944 72.7543 10.224 73.7863 9.648C74.8423 9.072 75.9343 8.784 77.0623 8.784C79.2223 8.784 80.8783 9.408 82.0303 10.656C83.2063 11.88 83.7943 13.632 83.7943 15.912V27H80.4823V16.56C80.4823 13.392 79.0783 11.808 76.2703 11.808C74.9263 11.808 73.7863 12.372 72.8503 13.5C71.9383 14.604 71.4823 15.9 71.4823 17.388V27H68.1703V1.224Z"
        fill="#4387F4"
      />
      <path
        d="M45.787 27H42.619V24.552H42.475C41.971 25.416 41.191 26.136 40.135 26.712C39.103 27.288 38.023 27.576 36.895 27.576C34.735 27.576 33.067 26.964 31.891 25.74C30.739 24.492 30.163 22.728 30.163 20.448V9.36H33.475V20.232C33.547 23.112 34.999 24.552 37.831 24.552C39.151 24.552 40.255 24.024 41.143 22.968C42.031 21.888 42.475 20.604 42.475 19.116V9.36H45.787V27Z"
        fill="#EA4436"
      />
      <path
        d="M52.2307 21.6C52.2307 22.464 52.5907 23.184 53.3107 23.76C54.0547 24.336 54.9187 24.624 55.9027 24.624C57.2947 24.624 58.5307 24.108 59.6107 23.076C60.7147 22.044 61.2667 20.832 61.2667 19.44C60.2347 18.624 58.7947 18.216 56.9467 18.216C55.6027 18.216 54.4747 18.54 53.5627 19.188C52.6747 19.836 52.2307 20.64 52.2307 21.6ZM56.5147 8.784C58.9627 8.784 60.8947 9.444 62.3107 10.764C63.7267 12.06 64.4347 13.848 64.4347 16.128V27H61.2667V24.552H61.1227C59.7547 26.568 57.9307 27.576 55.6507 27.576C53.7067 27.576 52.0747 27 50.7547 25.848C49.4587 24.696 48.8107 23.256 48.8107 21.528C48.8107 19.704 49.4947 18.252 50.8627 17.172C52.2547 16.092 54.1027 15.552 56.4067 15.552C58.3747 15.552 59.9947 15.912 61.2667 16.632V15.876C61.2667 14.724 60.8107 13.752 59.8987 12.96C58.9867 12.144 57.9187 11.736 56.6947 11.736C54.8467 11.736 53.3827 12.516 52.3027 14.076L49.3867 12.24C50.9947 9.936 53.3707 8.784 56.5147 8.784Z"
        fill="#F8BC06"
      />
      <path
        d="M103.198 27H99.202L93.694 18.684L90.994 21.348V27H87.682V1.224H90.994V17.1L98.626 9.36H102.874V9.504L95.998 16.344L103.198 26.856V27Z"
        fill="#37AA52"
      />
      <path
        d="M122.931 5.292L118.719 16.92H127.287L123.075 5.292H122.931ZM115.083 27H111.411L121.131 1.224H124.875L134.595 27H130.923L128.439 20.016H117.603L115.083 27ZM140.929 16.56V27H137.617V1.224H146.401C148.633 1.224 150.529 1.968 152.089 3.456C153.673 4.944 154.465 6.756 154.465 8.892C154.465 11.076 153.673 12.9 152.089 14.364C150.553 15.828 148.657 16.56 146.401 16.56H140.929ZM140.929 4.392V13.392H146.473C147.793 13.392 148.885 12.948 149.749 12.06C150.637 11.172 151.081 10.116 151.081 8.892C151.081 7.692 150.637 6.648 149.749 5.76C148.885 4.848 147.793 4.392 146.473 4.392H140.929ZM161.25 27H157.938V1.224H161.25V27Z"
        fill="#737373"
      />
    </svg>
  </div>
   
  <p class="m-1 text-center text-3xl">404... That’s an error.</p>
  <p class="m-1 text-center text-3xl text-gray-400">The requested resource was not found on this server.</p>
  <p class="m-1 text-center text-3xl text-gray-400">That’s all we know.</p>
</template>

<script>
// @ is an alias to /src
export default {
  name: "Home",
};
</script>
